import React from "react";
import Navbar from "./components/navbar";
import Hero from "./components/hero"
import DatingApplication from "./components/datingApplication"
import ContactUs from "./components/contactUs"

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <DatingApplication />
      <ContactUs />
    </div>
  );
}

export default App;
