import React from "react";
import { ReactTyped as Typed } from "react-typed";
import { Link } from "react-scroll";

const Hero = () => {
    return (
        <div className="text-white" id="hero">
            <div className="max-w-[800ox] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
                <p className="text-[#00df9a] font-bold p-2">GROWING WITH ARTIFICIAL INTELLIGENCE</p>
                <h1 className="md:text-6xl sm:text-5xl text-4xl font-bold md:py-6">Enchancing Everyday Apps</h1>
                <div className="flex justify-center items-center">
                    <p className="md:text-4xl sm:text-3xl text-xl font-bold py-4">
                        Integrating AI into the
                    </p>
                    <Typed
                    className="md:text-4xl sm:text-3xl text-xl font-bold md:pl-3 pl-2"
                    strings={["Music Space", "Dating Space"]} 
                    typeSpeed={160} 
                    backSpeed={180} 
                    loop
                    />
                </div>
                <p className="md:text-2xl text-xl font-bold text-gray-500">Follow the Journey and learn what our Innovative tools can do for you!</p>
                <button className="bg-[#00df9a] text-black w-[200px] rounded-md font-medium my-6 mx-auto py-3">
                <Link to="Contact" spy={true} smooth={true} offset={50} duration={500}>Get Started</Link>
                </button>
            </div>
        </div>
    )
}

export default Hero;