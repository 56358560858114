import React from "react";
import Amor from "../assets/adaptive-icon.png"

const DatingApplication = () => {
    return (
        <div className="w-full bg-white py-16 px-4" id="Amor">
            <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
                <img className="w-[500px] mx-auto my-4" src={Amor} alt="Amor Dating Application Logo" />
                <div className="flex flex-col justify-center">
                    <p className="text-[#00df9a] font-bold">Amor Dating Application</p>
                    <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">Find your perfect Match</h1>
                    <p>
                    Introducing Amor, the revolutionary dating app that takes matchmaking to the next level with cutting-edge AI technology. 
                    Unlike any other platform, Amor not only connects you with potential matches but also enhances your profile with personalized tips and suggestions. 
                    Say goodbye to awkward silences as Amor’s AI generates intriguing conversation starters, ensuring your chats are always engaging. 
                    Need to polish your communication skills? Amor's practice mode lets you chat with AI bots, offering a safe space to hone your conversation prowess. 
                    With Amor, building genuine connections has never been more exciting or effortless. Find love and elevate your dating experience today!
                    </p>

                </div>
            </div>

        </div>
    )
}

export default DatingApplication;