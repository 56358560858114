import React from "react";

const ContactUs = () => {
    return (
        <div className="w-full py-16 text-white px-4" id="Contact">
            <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
                <div className="lg:col-span-2 my-4">
                    <h1 className="md:text-4xl sm:text-4xl text-2xl font-bold py-2">Want to stay in the know?</h1>
                    <p>Sign up to our newsletter and stay up to date!</p>
                </div>
                <div className="my-4">
                    <div className="flex flex-col sm:flex-row items-center justify-between w-full">
                        <input type="email" placeholder="Enter Email" className="p-3 flex w-full rounded-md text-black"/>
                    <   button className="bg-[#00df9a] text-black w-[200px] rounded-md font-medium my-6 px-6 ml-4 py-3">Notify Me</button>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ContactUs;